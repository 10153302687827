/* eslint-disable no-nested-ternary */
import chroma from "chroma-js";
import React from "react";
import ReactSelect, { StylesConfig } from "react-select";

export interface SelectOptionProps {
  value: string;
  label: string;
  color: string;
}

export interface Props {
  backgroundColor: string;
  optionList: SelectOptionProps[];
  selectedOption?: SelectOptionProps[];
  hidden?: boolean;
}

export const Select: React.FC<Props> = ({
  backgroundColor,
  optionList,
  selectedOption,
  ...props
}: Props) => {
  const customStyles: StylesConfig<SelectOptionProps, true> = {
    control: (styles, { isFocused }) => ({
      ...styles,
      minHeight: "4rem",
      borderWidth: "0px",
      borderRadius: "10px",
      backgroundColor: backgroundColor || "#ffffff21",
      boxShadow: isFocused ? "#fff" : "none",
      ":active": {
        ...styles[":active"],
        boxShadow: "#fff",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : data.color,
        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        padding: "5px 10px",
        backgroundColor: `${data.color}`,
        borderRadius: "50px"
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "white",
      fontFamily: "Quicksand",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "22px",
      fontStyle: "normal",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: data.color
      },
    }),
  };

  return (
    <>
      <ReactSelect
        backspaceRemovesValue={false}
        defaultValue={selectedOption}
        isMulti
        placeholder=""
        styles={customStyles}
        options={optionList}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          ClearIndicator: () => null,
        }}
        {...props}
      />
    </>
  );
};
