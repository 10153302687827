import { useFormik } from "formik";
import React, { FC } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";

import { CustomizedLabelInput } from "../../components/core/inputs/CustomizedLabelInput";
import { INPUTS } from "../../constants/ui";
import { Dispatch, RootState } from "../../store/store";
import { RegisterPageWrapper } from "../RegisterPageWrapper";

type ISignUpCompanyPage = ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch>;

const SignUpCompanyPage: FC<ISignUpCompanyPage> = ({
  isAuthenticated,
  signUpCompany,
}) => {
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      company_name: "",
      vat_number: "",
      password: "",
      repeat_password: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email").required("Required"),
      company_name: Yup.string().required("Required"),
      vat_number: Yup.string().required("Required"),
      password: Yup.string()
        .min(6, "Minimum 6 characters required")
        .required("Required"),
      repeat_password: Yup.string().required("Passwords must match").oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
    }),
    onSubmit: async (values) => {
      await signUpCompany({
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        company_name: values.company_name,
        vat_number: values.vat_number,
        password: values.password,
        repeat_password: values.repeat_password,
      });
    },
  });

  return (
    <RegisterPageWrapper
      title="Opret virksomhed"
      action={{
        title: "Opret bruger",
        handler: formik.handleSubmit,
        disabled: !formik.isValid,
      }}
    >
      <div className="flex items-center mb-3 justify-between space-x-10 ">
        <CustomizedLabelInput
          autoFocus
          id="first_name"
          type="text"
          value={formik.values.first_name}
          onChange={formik.handleChange}
          error={formik.values.first_name && formik.errors.first_name}
          label="Fornavn"
          placeHolder="Dit fornavn..."
          styleType={formik.values.first_name && formik.errors.first_name ? INPUTS.ERROR : INPUTS.DEFAULT_HALF_WIDTH}
          disabled={false}
        />
        <CustomizedLabelInput
          id="last_name"
          value={formik.values.last_name}
          onChange={formik.handleChange}
          error={formik.values.last_name && formik.errors.last_name}
          label="Efternavn"
          placeHolder="Dit efternavn..."
          styleType={formik.values.last_name && formik.errors.last_name ? INPUTS.ERROR : INPUTS.DEFAULT_HALF_WIDTH}
          disabled={false}
        />
      </div>
      <div className="flex items-center mb-3 justify-between space-x-10 ">
        <CustomizedLabelInput
          id="company_name"
          value={formik.values.company_name}
          onChange={formik.handleChange}
          error={formik.values.company_name && formik.errors.company_name}
          label="Virksomhed"
          placeHolder="Virksomhedsnavn..."
          styleType={formik.values.company_name && formik.errors.company_name ? INPUTS?.ERROR : INPUTS.DEFAULT_HALF_WIDTH}
          disabled={false}
        />
        <CustomizedLabelInput
          id="vat_number"
          value={formik.values.vat_number}
          onChange={formik.handleChange}
          error={formik.values.vat_number && formik.errors.vat_number}
          label="CVR-nummer"
          placeHolder="CVR-nummer..."
          styleType={formik.values.vat_number && formik.errors.vat_number ? INPUTS.ERROR : INPUTS.DEFAULT_HALF_WIDTH}
          disabled={false}
        />
      </div>
      <div className="mb-6">
        <CustomizedLabelInput
          id="email"
          autoComplete="username"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.values.email && formik.errors.email}
          label="Din e-mailadresse"
          placeHolder="Din e-mailadresse..."
          styleType={formik.values.email && formik.errors.email ? INPUTS.ERROR : INPUTS.DEFAULT_FULL_WIDTH}
          disabled={false}
        />
      </div>
      <div className="flex items-center mb-3 justify-between space-x-10 ">
        <CustomizedLabelInput
          id="password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.values.password && formik.errors.password}
          label="Dit kodeord"
          placeHolder="Dit kodeord..."
          autoComplete="new-password"
          styleType={formik.values.password && formik.errors.password ? INPUTS.ERROR : INPUTS.DEFAULT_HALF_WIDTH}
          disabled={false}
        />
        <CustomizedLabelInput
          id="repeat_password"
          type="password"
          value={formik.values.repeat_password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.values.repeat_password && formik.errors.repeat_password}
          label="Bekræft kodeord"
          autoComplete="new-password"
          placeHolder="Dit kodeord..."
          styleType={formik.values.repeat_password && formik.errors.repeat_password ? INPUTS.ERROR : INPUTS.DEFAULT_HALF_WIDTH}
          disabled={false}
        />
      </div>
    </RegisterPageWrapper>
  );
};

const mapState = (state: RootState) => ({
  isAuthenticated: state.user?.isAuthenticated,
});

const mapDispatch = (dispatch: Dispatch) => ({
  signUpCompany: dispatch.user.signUpCompany,
});

export default connect(mapState, mapDispatch)(SignUpCompanyPage);
