/* eslint-disable no-console */
import { createModel } from "@rematch/core";
import { toast } from "react-toastify";

import history from "../../helpers/history";
import {
  cleanUserTokensFromLocalStorage,
  getAccessToken,
  getIsAuthenticated,
  getRefreshToken,
  setUserTokensToLocalStorage,
} from "../../helpers/user";
import {
  DELETE_USER_ACCESS_URL,
  DELETE_USER_URL,
  FORGOT_PASSWORD_URL,
  FULL_SIGN_OUT_URL,
  GET_COMPANY_SHARED_LINKS_URL,
  GET_USERS_BY_COMPANY_ID_URL,
  IS_VALID_LINK_URL,
  RESET_PASSWORD_URL, SEND_LINK_VIA_EMAIL_URL,
  SIGN_IN_URL,
  SIGN_OUT_URL,
  SIGN_UP_COMPANY_URL,
  SIGN_UP_URL,
  UPDATE_PROFILE_URL,
  UPLOAD_PROFILE_IMAGE_URL,
  USER_URL,
} from "../constants/api-contstants";
import { COMPANYSTATUS } from "../constants/company-constatns";
import { ADMIN_ROLE, CUSTOMER_ROLE, EDITOR_ROLE } from "../../constants/roles";
import { REGISTERED_MAIL_ROUTE, SIGN_IN_ROUTE, USER_REGISTERED_ROUTE, } from "../constants/route-constants";
import http from "../http/http-common";
import type { RootModel } from "./index";
import { ICompanyUser, ILink, IUserData, IUserLinkSignUp } from "./interfaces";

type UserState = {
  isAuthenticated: boolean;
  isEditor: boolean;
  isOwner: boolean;
  isAdmin: boolean;
  accessToken: "";
  refreshToken: "";
  data?: IUserData;
  links?: [ILink];
  companyUsers?: [ICompanyUser];
};

export const user = createModel<RootModel>()({
  state: {
    isAuthenticated: getIsAuthenticated(),
    accessToken: getAccessToken(),
    refreshToken: getRefreshToken(),
    isEditor: false,
    isOwner: false,
  } as UserState,
  reducers: {
    setIsAuthenticated(state, { isAuthenticated }) {
      return {
        ...state,
        isAuthenticated,
      } as UserState;
    },

    setIsOwner(state, { isOwner }) {
      return {
        ...state,
        isOwner,
      } as UserState;
    },

    setIsAdmin(state, { isAdmin }) {
      return {
        ...state,
        isAdmin,
      } as UserState;
    },

    setIsEditor(state, { isEditor }) {
      return {
        ...state,
        isEditor,
      } as UserState;
    },

    setTokens(state, { accessToken, refreshToken }) {
      return {
        ...state,
        accessToken,
        refreshToken,
      };
    },
    setUser(state, data) {
      return {
        ...state, data
      }
    },
    setLinks(state, links) {
      return {
        ...state, links
      }
    },
    setCompanyUsers(state, companyUsers) {
      return {
        ...state, companyUsers
      }
    }
  },
  effects: (dispatch) => ({
    async signUp(userData: IUserLinkSignUp) {
      try {
        const result: any = await http.post(SIGN_UP_URL, {
          email: userData.email,
          password: userData.password,
          first_name: userData.firstName,
          last_name: userData.lastName,
          token: userData.companyLink
        });
        if (result.request.status === 201) {
          await this.signIn({
            email: userData.email,
            password: userData.password,
          });
        }
        if (result.request.status === 400) {
          const message = result?.response?.data?.message || 'Error';
          toast.error(message);
        }
      } catch (err) {
        throw err;
      }
    },

    async signUpCompany({
      first_name,
      last_name,
      email,
      company_name,
      vat_number,
      password,
      repeat_password,
    }) {
      try {
        const result: any = await http.post(SIGN_UP_COMPANY_URL, {
          first_name,
          last_name,
          email,
          company_name,
          vat_number,
          password,
          repeat_password,
        });
        if (result.request.status === 201) {
          history.push(USER_REGISTERED_ROUTE);
        }
        if (result.request.status === 400) {
          const message = result?.response?.data?.message || 'Error';
          toast.error(message);
        }
      } catch (err) {
        throw err;
      }
    },

    async signIn({ email, password }) {
      try {
        const result = await http.post(SIGN_IN_URL, {
          username: email,
          password,
        });
        if (result.request.status === 201) {
          if (result.data.status === COMPANYSTATUS.ON_REVIEW) {
            history.push(REGISTERED_MAIL_ROUTE);
            return;
          }
          dispatch.user.setTokens({
            accessToken: result.data.accessToken,
            refreshToken: result.data.refreshToken,
          });
          setUserTokensToLocalStorage(
            result.data.accessToken,
            result.data.refreshToken
          );
          await this.getOwner();
          dispatch.user.setIsAuthenticated({ isAuthenticated: true });
          return;
        }
        toast.error("Forkerte legitimationsoplysninger");
      } catch (error) {
        throw error;
      }
    },

    async signOut() {
      try {
        const result = await http.post(SIGN_OUT_URL, {
          refreshToken: getRefreshToken(),
        });
        this.logOutUser();
      } catch (error) {
        this.logOutUser();
        throw error;
      }
    },

    async fullSignOut() {
      try {
        const result = await http.post(FULL_SIGN_OUT_URL);
        if (result.request.status === 201) {
          this.logOutUser();
        }
      } catch (error) {
        this.logOutUser();
        throw error;
      }
    },

    async resetPassword({ oldPassword, newPassword, confirmPassword }) {
      try {
        const result = await http.post(RESET_PASSWORD_URL, {
          oldPassword,
          newPassword,
          confirmPassword,
        });
        if (result.request.status === 201) {
          this.logOutUser();
        }
        if (result.request.status === 400) {
          toast.error("Adgangskoden passer ikke!");
        }
      } catch (err) {
        throw err;
      }
    },

    async forgotPassword({ username }) {
      await http.post(FORGOT_PASSWORD_URL, {
        username
      });
      toast.success("Din adgangskode er blevet nulstillet!");
    },

    logOutUser() {
      dispatch.user.setIsAuthenticated({ isAuthenticated: false });
      dispatch.businessPlan.clearBusinessPlanes();
      cleanUserTokensFromLocalStorage();
      history.push(SIGN_IN_ROUTE);
    },

    async getOwner() {
      const result = await http.get(`${USER_URL}`);
      if (result?.request.status === 200) {
        const userData: IUserData = result.data;
        const isAdmin = this.isAdmin(userData);
        await dispatch.user.setIsAdmin({ isAdmin });
        if (!isAdmin) {
          const isUserOwner = this.isOwner(userData);
          const isUserEditor = this.isEditor(userData);
          dispatch.user.setIsOwner({ isOwner: isUserOwner });
          dispatch.user.setIsEditor({ isEditor: isUserEditor });
        }
        dispatch.user.setUser(result.data);
        return result?.data?.company?.id;
      }
    },

    isOwner(userData: IUserData) {
      return userData.id === userData.company.owner.id;
    },

    isAdmin(userData: IUserData) {
      return [ADMIN_ROLE].includes(userData.role?.value);
    },

    isEditor(userData: IUserData) {
      return [ADMIN_ROLE, CUSTOMER_ROLE, EDITOR_ROLE].includes(userData.role?.value);
    },

    async getCompanyUsers(companyId: string) {
      try {
        const result = await http.get(GET_USERS_BY_COMPANY_ID_URL + `/${companyId}`);
        dispatch.user.setCompanyUsers(result.data);
      } catch (err) {
        throw err;
      }
    },

    async updateProfile({
      first_name,
      last_name,
      username,
      company_name,
      cvr_number
    }) {
      try {
        await http.put(UPDATE_PROFILE_URL, {
          first_name,
          last_name,
          username,
          company_name,
          cvr_number
        });
        toast.success("Profiloplysningerne blev opdateret!");
      } catch (err) {
        throw err;
      }
    },

    async uploadProfileImage(formData: FormData, state) {
      try {
        const result = await http.post(UPLOAD_PROFILE_IMAGE_URL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        const userData = state.user.data;
        dispatch.user.setUser(
          { ...userData, logo: result.data.Location },
        )
        toast.success("Profilbilledet blev opdateret");
      } catch (err) {
        throw err;
      }
    },

    async getCompanySharedLinks(companyId: string) {
      try {
        const result = await http.get(GET_COMPANY_SHARED_LINKS_URL + `/${companyId}`);
        dispatch.user.setLinks(result.data);
        return result.data;
      } catch (err) {
        throw err;
      }
    },

    async sendLinkViaEmail({ email, link }) {
      try {
        const result = await http.post(SEND_LINK_VIA_EMAIL_URL, { email, link });
        if (result.status === 201) {
          toast.success("Email has sent!");
        }
        if (result.status === 400) {
          toast.error("There is no company!")
        }
      } catch (err) {
        throw err;
      }
    },

    async isValidLink(link: string) {
      try {
        const result = await http.post(IS_VALID_LINK_URL, { token: link });
        return result.data;
      } catch (err) {
        throw err;
      }
    },

    async deleteAccess({ companyId, userId }) {
      try {
        const result = await http.post(DELETE_USER_ACCESS_URL, { companyId, userId });
        if (result.status === 201) {
          toast.success("Brugeren blev slettet!")
        }
        if (result.status === 404) {
          toast.error("Bruger ikke fundet for denne virksomhed!")
        }
        if (result.status === 400) {
          toast.error("Virksomhedsejeren kan ikke slettes!")
        }
      } catch (err) {
        throw err;
      }
    },

    async deleteUser(_, state) {
      try {
        if (!state.user.data?.id) return;
        const url = `${DELETE_USER_URL}?company_id=${state.user.data.company.id}`;
        const result = await http.delete(url);
        if (result.status === 200) {
          toast.success("Brugeren blev slettet!");
          this.logOutUser();
        }
      } catch (error) {
        throw error;
      }
    }
  }),
});
